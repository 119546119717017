<template>
<thead class="v-data-table-header header-filter-row" v-if="!isMobile">
<tr class="header-filter-row">
    <th v-for="head in headers" :key="head.value">
        <v-text-field v-model="head.search_term" v-if="(head.dataType === dataTypes.String || head.dataType === dataTypes.Number) && head.filterable" :autofocus="true" @keyup.enter="advanceFilterData(head)"></v-text-field>
        <v-select clearable :items="head.values" v-model="head.search_term" item-text="name" item-value="value" v-if="head.dataType === dataTypes.Enum && head.filterable" @change="advanceFilterData(head)"></v-select>        
    </th>
</tr>
</thead>
</template>
<script>
import configs from "@/configs";
import { isMobileAgent } from "@/commons/utils";
export default {
    name: 'table-header-filter',
    props: {
        headers: {
            type: Array,
            default: function() {return []}
        },
        viewName: String,
    },
    data() {
        return {
            dataTypes: configs.DATA_TYPE,
            comparions: configs.LIST_COMPARISON,
        }
    },
    methods: {
        advanceFilterData(column) {
            //Kiểm tra nếu chưa có điều kiện tìm kiếm thì thêm vào
            const existed = _.find(this.conditions, {column: column.value})
            let op = column.dataType === configs.DATA_TYPE['Date'] ? 'd_equal' : column.dataType === configs.DATA_TYPE['String'] ? 'contain' : column.dataType === configs.DATA_TYPE['Number'] ? 'equal' : configs.DATA_TYPE['Enum'] ? 'in' : 'equal';
            column.search_term = op == 'contain' ? column.search_term.trim() : op == 'equal' ? column.search_term.replace(/,/g, '') : column.search_term
            _.remove(this.conditions, {column: column.value, selectedCondition: op})
            if(column.search_term !== null && column.search_term !== '') {
                let condition_filters = _.filter(this.comparions, {
                    dataType: column.dataType
                });
                let condition = {
                    column: column.value,
                    name: column.text,
                    value:  op == 'in' ? [column.search_term] : column.search_term,
                    decimal: column.decimal || 0,
                    dataType: column.dataType,
                    listOfValue: column.values,
                    conditions: condition_filters,
                    selectedCondition: op
                };
                this.conditions = _.concat(this.conditions, [condition]);
            }
           
            const filter = _.map(this.conditions, obj => {
                return {
                    column: obj.column,
                    value: obj.value,
                    condition: obj.selectedCondition
                };
            });
            this.filterConditions = filter;
            this.$emit("applyFilter", filter);
        },
    },
    computed: {
        isMobile: isMobileAgent,
        conditions: {
            get() {
                let filters = this.$store.state.commons.filters;
                return !_.isEmpty(filters) && !_.isEmpty(filters[this.viewName]) ?
                    filters[this.viewName] : [];
            },
            set(value) {
                let filters = this.$store.state.commons.filters || {};
                filters[this.viewName] = value;
                this.$store.commit("commons/setFilters", {
                    filter: filters
                });
            }
        },
        filterConditions: {
            get() {
                let filterConditions = this.$store.state.commons.filterConditions;
                return !_.isEmpty(filterConditions) ?
                    filterConditions[this.viewName] : [];
            },
            set(value) {
                let filterConditions = this.$store.state.commons.filterConditions || {};
                filterConditions[this.viewName] = value;
                this.$store.commit("commons/setFilterConditions", {
                    filter: filterConditions
                });
            }
        },     
    }
}
</script>
<style scoped>
.v-data-table-header .header-filter-row,
.v-data-table-header .header-filter-row th,
.header-filter-row {
    background-color: rgba(190, 190, 190, 0.349) !important;
}
div.v-text-field__details {
 display:none !important;
}
.header-filter-row th div{
    padding: 0 !important;
}
</style>
<style lang="sass" scoped>
$text-field-line-height: 14px
</style>