<template>
    <div>
        <v-text-field v-if="isInputActive" type="text" :id="id" :rules="rules" v-bind:class='showClass' :color="color" :label="label" v-model="editValue" @blur="on_blur" :readonly="readonly" :disabled="Number(disabled) === 1 || disabled === ''" @keyup.enter="press_enter"/>
        <v-text-field v-else type="text" v-bind:class='showClass' :id="id" :color="color" :label="label"  v-model="displayValue" @blur="on_blur" @focus="isInputActive = true" :readonly="readonly" :disabled="Number(disabled) === 1 || disabled === ''" @keyup.enter="press_enter"/>
    </div>
</template>
<script>
export default {
    name: 'h-currency-input',
    props: {
        value: Number,
        label: String,
        disabled: Boolean,
        decimal: Number,
        showClass: String,
        readonly: Boolean,
        color: String,
        rules: Array,
        id: String,
        autoEmit: { type: Boolean, default: true }
    },
    data: function() {
        return {
            isInputActive: false,
            tempValue: 0,
            editValue: '',
        }
    },
    computed: {
        displayValue: {
            get: function() {
                let negative = false;
                negative = this.editValue.indexOf('-') == 0;
                var parseValue = Number(this.editValue.replace(/[^\d\.]/g, ""))
                let ret = this.editValue ? parseValue.toFixed(this.decimal !== undefined ? this.decimal : 0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") : '0'
                return (negative ? '-' : '') + ret;
            }
        }
    },
    watch: {
        isInputActive() {
            this.editValue = this.tempValue ? this.tempValue.toString() : '';
            this.$emit('on_focus', this.isInputActive);
        },
        value: function(newValue) {
            this.editValue = newValue && newValue !== undefined ? newValue.toString() : '';
            this.tempValue = newValue;
        },
        editValue: function(newValue) {
            let result = '';
            let negative = false;
            if (!newValue) {
                result = '';
            }
            else {
                negative = newValue.indexOf('-') == 0;
                // Ensure that it is not NaN
                if (isNaN(parseFloat(newValue.replace(/[^\d\.]/g, "")))) {
                    result = '';
                }
                else {
                    result = newValue.replace(/[^\d\.]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            }
            this.tempValue = result == '' ? 0 : (negative ? -1 : 1) * parseFloat(parseFloat(result.replace(/[^\d\.]/g, "")).toFixed(this.decimal !== undefined ? this.decimal : 0));
            if(this.autoEmit && !this.disabled) {
                this.$emit('input', this.tempValue)
            }
            // this.$nextTick(() => this.editValue = (negative ? '-' : '') + result);
        }
    },
    mounted() {
        this.editValue = this.value && this.value !== undefined ? this.value.toString() : '';
        this.tempValue = this.value;
    },
    methods: {
        press_enter(e) {
            if(!this.autoEmit) {
                this.$emit('input', Number(this.tempValue))
            }
            this.$emit('press_enter', Number(this.tempValue))
        },
        on_blur() {
            this.isInputActive = false;
            if(!this.autoEmit) {
                this.$emit('input', Number(this.tempValue))
            }
            this.$emit('on_blur', Number(this.tempValue))
        }
    }
}
</script>
